import React, {memo} from 'react';
import {Link} from 'gatsby';
import styled from 'styled-components';

const FloatingAnchor = ({id, to, title}) => (
  <Container>
        <Link id={id} to={to} title={title} >
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 7.5H9C8.20435 7.5 7.44129 7.81607 6.87868 8.37868C6.31607 8.94129 6 9.70435 6 10.5V28.5C6 29.2956 6.31607 30.0587 6.87868 30.6213C7.44129 31.1839 8.20435 31.5 9 31.5H17.5455M12 7.5C12 6.70435 12.3161 5.94129 12.8787 5.37868C13.4413 4.81607 14.2044 4.5 15 4.5H18C18.7956 4.5 19.5587 4.81607 20.1213 5.37868C20.6839 5.94129 21 6.70435 21 7.5M12 7.5C12 8.29565 12.3161 9.05871 12.8787 9.62132C13.4413 10.1839 14.2044 10.5 15 10.5H18C18.7956 10.5 19.5587 10.1839 20.1213 9.62132C20.6839 9.05871 21 8.29565 21 7.5M27 21V27H33M27 21C28.5913 21 30.1174 21.6321 31.2426 22.7574C32.3679 23.8826 33 25.4087 33 27M27 21C25.4087 21 23.8826 21.6321 22.7574 22.7574C21.6321 23.8826 21 25.4087 21 27C21 28.5913 21.6321 30.1174 22.7574 31.2426C23.8826 32.3679 25.4087 33 27 33C28.5913 33 30.1174 32.3679 31.2426 31.2426C32.3679 30.1174 33 28.5913 33 27M27 16.5V10.5C27 9.70435 26.6839 8.94129 26.1213 8.37868C25.5587 7.81607 24.7956 7.5 24 7.5H21M12 16.5H18M12 22.5H16.5" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
            <TooltipText>{title}</TooltipText>
        </Link>
  </Container>
);

const TooltipText = styled.span`
    display: none;

    @media screen and (min-width: 480px) {
        visibility: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 6px 8px;
        background-color: #2E3337;
        color: #fff;
        text-align: center;
        border-radius: 5px;
        position: absolute;
        z-index: 1;
        bottom: 5px;
        right: 55px;
        text-wrap: nowrap;
        opacity: 0;
        transition: opacity 0.3s;
    }
    @media screen and (min-width: 1040px) {
        padding: 6px 8px;
        bottom: 12px;
        right: 70px;

    }
`;

const Container = styled.div`
    position: fixed;
    top: 7px;
    right: 30px;
    z-index: 999;

    @media screen and (min-width: 1040px) and (max-height: 770px) {
        top: 330px;
   }
    @media screen and (min-width: 1040px) and (min-height: 771px) {
        top: 712px;
    }
    @media screen and (min-width: 1800px) {
        right: calc((100vw - 1300px)/2 - 200px);
    }


  a {
    position: sticky;
    width: 45px;
    height: 45px;
    background-color: #145FFF;
    border-radius: 50%;
    box-shadow: 3px 3px 6px #00000029;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;

    display: flex;

    & svg{
        width: 28px;
        height: 28px;
    }

    @media screen and (min-width: 1040px) {
        width: 60px;
        height: 60px;

        & svg{
            width: 36px;
            height: 36px;
        }
    }

  }
  a:hover span{
      visibility: visible;
      opacity: 1;
  }
`;

export default memo(FloatingAnchor);
